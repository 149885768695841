import { useAppsStore } from "~/store/apps";

export function useGetPageTitle() {
  const appsStore = useAppsStore();
  const route = useRoute();

  return appsStore.activeNav?.functions?.flat(Infinity)?.length
    ? appsStore.activeNav?.functions
        ?.flat(Infinity)
        ?.find(
          (item) =>
            item.link === route.path?.toString() ||
            item.functions
              ?.flat(Infinity)
              ?.find((subitem) => subitem.link === route.path?.toString())
        )?.name
    : appsStore.activeNav?.name ?? appsStore.activeNav?.title;
}
